* {
  box-sizing:border-box;
  padding:0;
  margin:0;
}

p, ol, ul {
  margin-bottom: 1em;
}

body {
  background:#ffffff;
  font-family: 'Roboto', sans-serif;
  top: 0px !important;
}

.MuiSvgIcon-root, .MuiStepLabel-iconContainer {
  color: #3D6017 !important;
}

.MuiSvgIcon-root > .MuiStepLabel-root > .MuiStepLabel-iconContainer {
  background: #3D6017 !important;
}

.MuiStepLabel-iconContainer > div > svg {
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 4px;
  height: 24px;
  width: 24px;
}

.MuiStepLabel-iconContainer > div > svg:last-of-type {
  padding-left: 5px;
  padding-right: 5px;
}

.MuiStepConnector-root > .MuiStepConnector-line {
  border-color: #e83e8c !important;
}

.fa-svg-icon {
  width: 2em;
  height: 2em;
  vertical-align: -0.125em;
}

/* div#view-default-view, div#view-MapView {
    z-index:500;
} */

/* bottom drawer */
#bottom-drawer.hidden {
    bottom:-266px;
}

/* break-after: avoid; */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif !important;
    font-size: 13px;
    /* background-color: #111333; */
    overflow-x: hidden;
  }

#mainContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height:  100%;
}

#mainContainer.loading {
  opacity:0.7;
}

#mainContainer.loading #variablePanel {
  pointer-events: none;
}

#root {
  height:calc(100% - 50px);
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin:7px 7px 0 0;
}
.mapboxgl-ctrl-top-right {
  position: fixed !important;
  max-height: 43px !important;
  overflow:hidden !important;
}

/* svg styles */

.cls-1 {
  stroke-miterlimit:10;
  stroke-width:4px;
}

/* mapbox fix */

button.mapboxgl-ctrl-attrib-button {
  display:none;
}

#view-main {
  z-index:0;
  clip-path:url(#window);
}
/*
#view-alaskaMap, #view-hawaiiMap {
  border:1px solid white;
  z-index:1;
} */

.highlighted {
  animation: highlightedShadow 1.5s linear infinite;
  transition:250ms all;
  /* box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important; */
}

@keyframes highlightedShadow {
  0% {box-shadow:inset 0px 0px 0.5vw #FFCE00;}
  25% {box-shadow:inset 0px 0px 0.5vw #FFCE0055;}
  50% {box-shadow:inset 0px 0px 0.5vw #FFCE00;}
  75% {box-shadow:inset 0px 0px 0.5vw #FFCE0055;}
  100% {box-shadow:inset 0px 0px 0.5vw #FFCE00;}
}

/* @keyframes highlightedShadow {
  0% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
  25% {box-shadow:5px -5px 3px red, -5px 5px 3px red, 5px 5px 3px red, -5px -5px 3px red !important;}
  50% {box-shadow:-5px -5px 3px red, 0px 0px 3px red, 0px -5px 3px red, 0px 5px 3px red !important;}
  75% {box-shadow:5px 0px 3px red, -5px -5px 3px red, 5px 5px 3px red, -5px 0px 3px red !important;}
  100% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
}

@-webkit-keyframes highlightedShadow {
  0% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
  25% {box-shadow:5px -5px 3px red, -5px 5px 3px red, 5px 5px 3px red, -5px -5px 3px red !important;}
  50% {box-shadow:-5px -5px 3px red, 0px 0px 3px red, 0px -5px 3px red, 0px 5px 3px red !important;}
  75% {box-shadow:5px 0px 3px red, -5px -5px 3px red, 5px 5px 3px red, -5px 0px 3px red !important;}
  100% {box-shadow:0px 0px 3px red, -5px 0px 3px red, 5px -5px 3px red, 0px 5px 3px red !important;}
} */

#loadingIcon {
  position: fixed;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  width: 100px;
  height:100px;
  z-index:50;
  background-size:cover;
  background-repeat: no-repeat;
  opacity:1;
  pointer-events: none;
  mix-blend-mode: lighten;
}
#loadingIcon:after {
  content: 'Loading Data...';
  color:black;
  font-weight: bold;
  position: absolute;
  bottom:-30px;
  text-align: center;
  width:100%;
  background:white;
}

.mapboxgl-ctrl-bottom-left {
  transform:translateY(-20px);
}

.mapboxgl-ctrl-bottom-right {
  left:0 !important;
  right:initial !important;
}

#nav-container div.MuiPaper-root {
  transform: translate(-5px, 5px) !important;
  background: rgba( 255, 255, 255, 0.85 );
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.85 ); */
  backdrop-filter: blur( 20px );
  -webkit-backdrop-filter: blur( 20px );
  box-shadow: 2px 0px 5px gray;
  border:1px solid green;
  border-radius:0;
}

/* Hide Google Analytics Top Bar so it won't overlap with Menu */
iframe.skiptranslate { visibility: hidden !important; }
